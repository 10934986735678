import axios from "axios";

const getClient = opts => {
  const options = {
    baseURL: opts.baseUrl || null
  };

  const client = axios.create(options);

  client.interceptors.request.use(
    requestConfig => requestConfig,
    requestError => {
      return Promise.reject(requestError);
    }
  );

  client.interceptors.response.use(
    response => response,
    error => {
      return Promise.reject(error);
    }
  );

  return client;
};

function updateConf(conf) {
  const { sendCurrentUrl = true, params = {}, ...rest } = conf;

  if (sendCurrentUrl === true) {
    params.currentUrl = window.location.href;
  }

  return {
    ...rest,
    params: {
      ...params
    }
  };
}

function makeRequest(type, url, conf = {}) {
  return getClient({})[type](url, updateConf(conf))
    .then(response => Promise.resolve(response))
    .catch(error => Promise.reject(error));
}

function makeRequestWithData(type, url, data = {}, conf = {}) {
  return getClient({})[type](url, data, updateConf(conf))
    .then(response => Promise.resolve(response))
    .catch(error => Promise.reject(error));
}

export const http = {
  get(url, conf = {}) {
    return makeRequest("get", url, conf);
  },
  delete(url, conf = {}) {
    return makeRequest("delete", url, conf);
  },
  head(url, conf = {}) {
    return makeRequest("head", url, conf);
  },
  options(url, conf = {}) {
    return makeRequest("options", url, conf);
  },
  post(url, data = {}, conf = {}) {
    return makeRequestWithData("post", url, data, conf);
  },
  put(url, data = {}, conf = {}) {
    return makeRequestWithData("put", url, data, conf);
  },
  patch(url, data = {}, conf = {}) {
    return makeRequestWithData("patch", url, data, conf);
  }
};
