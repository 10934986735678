import React, { useState } from "react";
import ColorPicker from "rc-color-picker";
import "./style.scss";

export const ColorPickerComponent = ({ category, type, text, setColor, state }) => {
  const initialColor = state.global[category][type] ? state.global[category][type] : "#ffffff";
  const [color, setState] = useState(initialColor);

  const changeHandler = (e) => {
    if (e.color === color) return
    setState(e.color);

    setColor((prevState) => {
      prevState.global[category][type] = e.color;
      const newState = { ...prevState };

      return newState;
    });
  };

  const buttonClick = () => {
    setState("#ffffff");

    setColor((prevState) => {
      prevState.global[category][type] = null;
      const newState = { ...prevState };

      return newState;
    });
  }
  return (
    <div className="menu_item">
      <span className="color-picker-text" >{text}</span>
      <ColorPicker animation="slide-up" color={color} onClose={changeHandler} />
      <span className="color-picker-reset" onClick={() => buttonClick()}>reset</span>
    </div>
  );
};
