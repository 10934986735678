import React, { useState, useRef } from "react";
import { BarsLinesColorPicker } from "../colorPickerBarsLines";
import { settings } from "../../../../settings";
import tinycolor from "tinycolor2";
import "./style.scss";

export const BarsMenu = ({ setState, state }) => {
  const initialPickers =
    state.global.barColors.length > 24 ? state.global.barColors : settings.bars;
  const inputFirst = useRef(null);
  const inputSecond = useRef(null);
  const inputThird = useRef(null);
  const inputFourths = useRef(null);
  const inputFifths = useRef(null);

  const [pickers, addPicker] = useState(initialPickers);
  const generate = () => {
    let primaryColorsLength = 3;
    let primaryColors = [];
    inputFirst.current.value && primaryColors.push(inputFirst.current.value);
    inputSecond.current.value && primaryColors.push(inputSecond.current.value);
    inputThird.current.value && primaryColors.push(inputThird.current.value);
    inputFourths.current.value &&
      primaryColors.push(inputFourths.current.value);
    inputFifths.current.value && primaryColors.push(inputFifths.current.value);

    for (let n = 9; n >= 2; n -= 1) {
      for (let s = 0; s < primaryColorsLength; s++) {
        var hsl = tinycolor(primaryColors[s]).toHsl();
        hsl.l = 0.1 * n;
        primaryColors.push("#" + tinycolor(hsl).toHex());
      }
    }
    const newColors = primaryColors.slice(0, pickers.length);

    setState((prevState) => {
      const newState = {
        ...prevState,
      };
      newState.global.barColors = newColors;
      return {
        ...newState,
      };
    });
  };
  return (
    <div className="bars-menu_wrapper">
      <button
        onClick={() =>
          addPicker((prevState) => [...prevState, `${pickers.length}`])
        }
      >
        add Picker
      </button>
      <button onClick={() => generate()}> generate</button>
      <input
        ref={inputFirst}
        type="text"
        pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$"
      />
      <input
        ref={inputSecond}
        type="text"
        pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$"
      />
      <input
        ref={inputThird}
        type="text"
        pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$"
      />
      <input
        ref={inputFourths}
        type="text"
        pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$"
      />
      <input
        ref={inputFifths}
        type="text"
        pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$"
      />
      {pickers.map((item, index) => {
        return (
          <BarsLinesColorPicker
            setState={setState}
            state={state}
            colorInitial={state.global.barColors[index]}
            key={item}
            type="barColors"
            index={index}
          />
        );
      })}
    </div>
  );
};
