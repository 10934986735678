import { takeEvery, put } from "redux-saga/effects";
import { PUBLISH_GLOBAL_CONFIG, GET_GLOBAL_CONFIG } from "./consts";
import { http } from "../utils/api";
import { settings } from "../../settings";
import { actionSetGlobalConfig, actionResetGlobalConfig } from "./actions";

export function* globalSaga() {
  yield takeEvery(GET_GLOBAL_CONFIG, (payload) => getGlobalConfig(payload));
  yield takeEvery(PUBLISH_GLOBAL_CONFIG, (payload) => publishGlobalConfig(payload));
}

function* getGlobalConfig({ payload }) {
  const config = yield http
    .get(`${settings.apiUrl}/company/${payload}`)
    .then((response) => response.data);

  if (config && config.configuration) {
    yield put(actionSetGlobalConfig(config.configuration.global));
  } else {
    yield put(actionResetGlobalConfig());
  }
}

function* publishGlobalConfig({payload}) {
  yield http.post(`${settings.apiUrl}/company/`, {
    UUID: payload.UUID,
    configuration: payload.state
  })
}