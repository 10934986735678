import { SET_GLOBAL_CONFIG, GET_GLOBAL_CONFIG, RESET_GLOBAL_CONFIG, PUBLISH_GLOBAL_CONFIG } from "./consts";

export const actionSetGlobalConfig = (payload) => ({
  type: SET_GLOBAL_CONFIG,
  payload,
});

export const actionGetConfig = (payload) => ({
  type: GET_GLOBAL_CONFIG,
  payload
})

export const actionResetGlobalConfig = () => ({
  type: RESET_GLOBAL_CONFIG
})

export const actionPublishGlobalConfig = (payload) => ({
  type: PUBLISH_GLOBAL_CONFIG,
  payload
})
