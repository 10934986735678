import { SET_GLOBAL_CONFIG, RESET_GLOBAL_CONFIG } from "./consts";
import deepmerge from "deepmerge";

export const initialState = {
  global: {
    fonts: {
      titleSymbol: null,
      subHeader: null,
      body: null,
      tableHeader: null,
      tabSelected: null,
      tabNotSelected: null,
      button: null,
      dialog: null,
      link: null
    },
    backgroundColors: {
      widget: null,
      tableHeader: null,
      tableRow1: null,
      tableRow2: null,
      tabBottomBarSelected: null,
      button: null,
      buttonHover: null,
      dialog: null,
      topBottomLegend: null,
      dateLegend: null,
      legendButton: null,
      legendButtonBorder: null,
    },
    fontFamily: {
      en: null,
      ar: null,
    },
    barColors: [],
    lineColors:[]
  },
};

export const globalReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_GLOBAL_CONFIG:

    const newInitial = initialState.global;
    const newState = deepmerge(newInitial, payload)
      return {
        ...state,
        global: newState,
      };
      case RESET_GLOBAL_CONFIG: 
      return {
          ...initialState
        }
      
    default:
      return state;
  }
};
