import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { settings } from "../../../settings";
import { http } from "../../utils/api";
import { actionSetGlobalConfig } from "../../Global/actions";

export const useGetInitialData = (setAppReady, setCurrentCompany) => {
  const [companies, setComapnies] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const getCompanies = async () => {
      const companies = await http
        .get("https://test.api.meds.atnmo.com/news-feed/companies")
        .then((response) => response.data);
        const listNullSymbolConpanies = [];
        companies.forEach(element => {
          if (element.listingSymbol === null) {
            element.listingSymbol = "";
            listNullSymbolConpanies.push(element)
          }});
      const updateResponse = companies.filter((a) => a.listingSymbol !== "");
      updateResponse.sort((a, b) => a.listingSymbol.localeCompare(b.listingSymbol));
      listNullSymbolConpanies.forEach(element => {
        updateResponse.push(element)
      });
      setCurrentCompany(updateResponse[0]);
      setComapnies(updateResponse);

      try {
        const initialConfig = await http
          .get(`${settings.apiUrl}/company/${companies[0].companyId}`)
          .then((response) => response.data);

        if (initialConfig && initialConfig.configuration) {
          dispatch(actionSetGlobalConfig(initialConfig.configuration.global));
        }
      } catch (e) {
        console.log(e);
      }

      setAppReady(true);
    };
    getCompanies();
  }, [setAppReady, setCurrentCompany, dispatch]);

  return [companies];
};
