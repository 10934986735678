import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { Layout, Collapse } from "antd";
import { LinkBack } from "../common/components/linkBack";
import { FontsMenu } from "./components/fontsMenu";
import { BarsMenu } from "./components/barsMenu";
import { LinesMenu } from "./components/linesMenu";
import { SubHeader } from "./components/subHeader";
import { Instructions } from "./components/instructionMenu";
import { BackgroundMenu } from "./components/backgroundMenu";
import { actionPublishGlobalConfig } from "./actions";
import IframeResizer from "iframe-resizer-react";
import { ToastContainer } from "react-toastify";
import { Slide } from "react-toastify";
import { settings } from "../../settings";
import "react-toastify/dist/ReactToastify.css";

import "rc-color-picker/assets/index.css";
import "./style.scss";

export const Global = ({ company }) => {
  const { Sider, Content } = Layout;
  const { Panel } = Collapse;
  const globalState = useSelector((state) => state.global);

  const overviewRef = useRef();
  const highlightsRef = useRef();
  const chartsHighlightsRef = useRef();
  const factsStockPriceRef = useRef();
  const stockPriceRef = useRef();
  const corporateActionsRef = useRef();
  const financialCalendarRef = useRef();
  const financialHighlightsRef = useRef();

  const [globalStyles, setState] = useState(globalState);

  useEffect(() => {
    setState(globalState);
  }, [globalState]);

  useEffect(() => {
    if (globalStyles) {
      overviewRef.current.sendMessage(globalStyles);
      highlightsRef.current.sendMessage(globalStyles);
      chartsHighlightsRef.current.sendMessage(globalStyles);
      factsStockPriceRef.current.sendMessage(globalStyles);
      stockPriceRef.current.sendMessage(globalStyles);
      corporateActionsRef.current.sendMessage(globalStyles);
      financialCalendarRef.current.sendMessage(globalStyles);
      financialHighlightsRef.current.sendMessage(globalStyles);
    }
  }, [globalStyles]);

  return (
    <Layout>
      <Sider
        className="global_sider"
        style={{ position: "fixed", zIndex: 1, top: "64px" }}
      >
        <LinkBack name="Global" />
        <Collapse>
          <Panel header="FONT" key="1">
            <FontsMenu setState={setState} state={globalStyles} />
          </Panel>
          <Panel header="BACKGROUND" key="2">
            <BackgroundMenu setState={setState} state={globalStyles} />
          </Panel>
          <Panel header="BAR COLOR PALETTE" key="3">
            <BarsMenu setState={setState} state={globalStyles} />
          </Panel>
          <Panel header="LINE COLOR PALETTE" key="4">
            <LinesMenu setState={setState} state={globalStyles} />
          </Panel>
          <Panel header="INSTRUCTIONS PAGE LINKS" key="5">
            <Instructions UUID={company.companyId} listingId={company.listingId}/>
          </Panel>
        </Collapse>
      </Sider>
      <Content className="content_wrapper">
        <SubHeader
          action={actionPublishGlobalConfig}
          state={globalStyles}
          UUID={company.companyId}
        />
        <div className="tittle-wrapper">
          <h1>Company Snapshot</h1>
        </div>
        <IframeResizer
          src={`https://${settings.urlWidget}irp.atnmo.com/v1/widget/company-snapshot?UUID=${company.companyId}&listingId=${company.listingId}&dataSource=${settings.envName}`}
          width="100%"
          frameBorder="0"
          forwardRef={overviewRef}
          style={{ border: "1px solid #e0e0e0" }}
        />
        <div className="tittle-wrapper">
          <h1>Fact Sheet Table</h1>
        </div>
        <IframeResizer
          src={`https://${settings.urlWidget}irp.atnmo.com/v1/widget/fact-sheet-table?UUID=${company.companyId}&listingId=${company.listingId}&dataSource=${settings.envName}`}
          width="100%"
          frameBorder="0"
          forwardRef={highlightsRef}
          style={{ border: "1px solid #e0e0e0" }}
        />
        <div className="tittle-wrapper">
          <h1>Fact Sheet Charts</h1>
        </div>
        <IframeResizer
          src={`https://${settings.urlWidget}irp.atnmo.com/v1/widget/fact-sheet-charts?UUID=${company.companyId}&listingId=${company.listingId}&dataSource=${settings.envName}`}
          width="100%"
          frameBorder="0"
          forwardRef={chartsHighlightsRef}
          style={{ border: "1px solid #e0e0e0" }}
        />
        <div className="tittle-wrapper">
          <h1>Stock Activity - Simple</h1>
        </div>
        <IframeResizer
          src={`https://${settings.urlWidget}irp.atnmo.com/v1/widget/stock-activity-simple?UUID=${company.companyId}&listingId=${company.listingId}&dataSource=${settings.envName}`}
          width="100%"
          frameBorder="0"
          forwardRef={factsStockPriceRef}
          style={{ border: "1px solid #e0e0e0" }}
        />
        <div className="tittle-wrapper">
          <h1>Stock Activity - Advanced</h1>
        </div>
        <IframeResizer
          src={`https://${settings.urlWidget}irp.atnmo.com/v1/widget/stock-activity-advanced?UUID=${company.companyId}&listingId=${company.listingId}&dataSource=${settings.envName}`}
          width="100%"
          frameBorder="0"
          forwardRef={stockPriceRef}
          style={{ border: "1px solid #e0e0e0" }}
        />
        <div className="tittle-wrapper">
          <h1>Corporate Actions</h1>
        </div>
        <IframeResizer
          src={`https://${settings.urlWidget}irp.atnmo.com/v1/widget/corporate-actions?UUID=${company.companyId}&listingId=${company.listingId}&dataSource=${settings.envName}`}
          width="100%"
          frameBorder="0"
          forwardRef={corporateActionsRef}
          style={{ border: "1px solid #e0e0e0" }}
        />
        <div className="tittle-wrapper">
          <h1>Corporate News</h1>
        </div>
        <IframeResizer
          src={`https://${settings.urlWidget}irp.atnmo.com/v1/widget/corporate-news?UUID=${company.companyId}&listingId=${company.listingId}&dataSource=${settings.envName}`}
          width="100%"
          frameBorder="0"
          forwardRef={financialCalendarRef}
          style={{ border: "1px solid #e0e0e0" }}
        />
        <div className="tittle-wrapper">
          <h1>Company Financials</h1>
        </div>
        <IframeResizer
          src={`https://${settings.urlWidget}irp.atnmo.com/v1/widget/company-financials?UUID=${company.companyId}&listingId=${company.listingId}&dataSource=${settings.envName}`}
          width="100%"
          frameBorder="0"
          forwardRef={financialHighlightsRef}
          style={{ border: "1px solid #e0e0e0" }}
        />
        <div className="tittle-wrapper">
          <h1>Investment Calculator</h1>
        </div>
        <IframeResizer
          src={`https://${settings.urlWidget}irp.atnmo.com/v1/widget/investment-calculator?UUID=${company.companyId}&listingId=${company.listingId}&dataSource=${settings.envName}`}
          width="100%"
          frameBorder="0"
          forwardRef={financialHighlightsRef}
          style={{ border: "1px solid #e0e0e0" }}
        />
      </Content>
      <ToastContainer
        draggable={false}
        transition={Slide}
        autoClose={false}
        closeButton={false}
        hideProgressBar={true}
      />
    </Layout>
  );
};
