import React from "react";
import { PublishButton } from "../../../common/components/publishButton";
import "./styles.scss";

export const SubHeader = ({ action, state, UUID }) => {
  return (
    <div className="global_subheader">
      <PublishButton action={action} state={state} UUID={UUID} />
    </div>
  );
};
