import React, { useState } from "react";
import { BarsLinesColorPicker } from "../colorPickerBarsLines";
import { settings } from "../../../../settings";

import "./style.scss";

export const LinesMenu = ({ setState, state }) => {
  const initialPickers =
    state.global.lineColors.length > 5
      ? state.global.lineColors
      : settings.lines;
  const [pickers, addPicker] = useState(initialPickers);

  return (
    <div className="lines-menu_wrapper">
      <button
        onClick={() =>
          addPicker((prevState) => [...prevState, `${pickers.length}`])
        }
      >
        add Picker
      </button>
      {pickers.map((item, index) => {
        return (
          <BarsLinesColorPicker
            setState={setState}
            state={state}
            colorInitial={state.global.lineColors[index]}
            key={item}
            type="lineColors"
            index={index}
          />
        );
      })}
    </div>
  );
};
