import React, { useState } from "react";
import { Layout, Dropdown } from "antd";
import { createBrowserHistory } from "history";
import { useDispatch } from "react-redux";
import { Router, Route, Switch } from "react-router";
import { useGetInitialData } from "./modules/common/hooks/useGetInitialData";
import { actionGetConfig } from "./modules/Global/actions";
import "./App.scss";
import { Main, Global } from "./modules";

export const App = () => {
  const { Header } = Layout;
  const [isAppReady, setAppReady] = useState(false);
  const [currentCompany, setCurrentCompany] = useState();
  const [companies] = useGetInitialData(setAppReady, setCurrentCompany);
  const dispatch = useDispatch();

  const history = createBrowserHistory();

  const setCurrentCompanyConfig = (item) => {
    dispatch(actionGetConfig(item.id));
    setCurrentCompany(item);
  };

  const menu = (
    <div className="scrolling-menu">
      {companies.map((item, index) => {
        return (
          <div
            key={index}
            className="scrolling-menu-item"
            onClick={() => setCurrentCompanyConfig(item)}
          >
            <span>{`${item.listingSymbol} ${item.name}`}</span>
          </div>
        );
      })}
    </div>
  );

  return isAppReady ? (
    <div className="App">
      <Layout>
        <Header className="main_header">
          <div className="header_icon-wrapper">
            <img
              className="header_icon"
              src="./icons/autonomotech-avatar-onDark.png"
              alt="icon"
            />
            <div className="header_text">
              <span style={{ color: "white" }}>Config Admin</span>
            </div>
          </div>
          <Dropdown
            overlay={menu}
            trigger={["click"]}
            overlayClassName="header_dropdown"
          >
            <span
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
            >
              {`${currentCompany.listingSymbol} ${currentCompany.name}`}
            </span>
          </Dropdown>
        </Header>
        <Router history={history}>
          <Switch>
            <Route path={["/", "/main"]} exact component={() => <Main />} />
          </Switch>
          <Switch>
            <Route
              path="/global"
              exact
              component={() => <Global company={currentCompany} />}
            />
          </Switch>
        </Router>
      </Layout>
    </div>
  ) : null;
};

export default App;
