import React from "react";
import { Layout } from "antd";
import { widgets } from "../../settings";

import "./style.scss";

export const Main = () => {
  const { Sider, Content } = Layout;

  return (
    <Layout>
      <Sider className="main_sider" style={{ position: 'fixed', zIndex: 1, top: "64px"}}>
        <div className="sider_item">
          <a href="/global">Global </a>
        </div>
        <div className="sider_item">
          <span > Widgets</span>
        </div>
        {widgets.map(item => {
          return (
            <div key={item.name} className="sider_item">
              <a href={item.href}>
                {item.name}
              </a>
            </div>
          );
        })}
      </Sider>
      <Content>Content</Content>
    </Layout>
  );
};
