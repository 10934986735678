import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

import "./styles.scss";

export const Instructions = ({ UUID, listingId }) => {
  const enUrl = `https://irp.atnmo.com/v1/widget/custom-widgets?UUID=${UUID}&listingId=${listingId}&lang=en`;
  const arUrl = `https://irp.atnmo.com/v1/widget/custom-widgets?UUID=${UUID}&listingId=${listingId}&lang=ar`;

  return (
    <div className="instructions-wrapper">
      <div className="instructions-wrapper-item">
        <a href={enUrl} target="_blank" rel="noopener noreferrer">
          English link
        </a>
        <CopyToClipboard text={enUrl}>
          <button>Save to clipboard</button>
        </CopyToClipboard>
      </div>
      <div className="instructions-wrapper-item">
        <a href={arUrl} target="_blank" rel="noopener noreferrer">
          Arabic link
        </a>
        <CopyToClipboard text={arUrl}>
          <button>Save to clipboard</button>
        </CopyToClipboard>
      </div>
    </div>
  );
};
