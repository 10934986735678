import React, { useState } from "react";
import ColorPicker from "rc-color-picker";

export const BarsLinesColorPicker = ({
  setState,
  type,
  colorInitial,
  index,
}) => {
  const initialColor = colorInitial ? colorInitial : "#ffffff";
  const [color, setNewColor] = useState(initialColor);

  const changeHandler = (e) => {
    if (e.color === color) return;
    setNewColor(e.color);
    setState((prevState) => {
        if (!prevState.global[type][index]) {
          return {
            global: {
              ...prevState.global,
              [type]: [...prevState.global[type], e.color],
            },
          };
        } else {
          prevState.global[type][index] = e.color;
          return {
            global: {
              ...prevState.global,
              [type]: [...prevState.global[type]],
            },
          };
        }
    });
  };
  return (
    <ColorPicker animation="slide-up" color={initialColor} onClose={changeHandler} />
  );
};
