export const widgets = [
  {
    name: "Fact Sheet",
    href: "/fact-sheet",
  },
  {
    name: "Financial Calendar",
    href: "/financial-calendar",
  },
  {
    name: "Stock price",
    href: "/stock-price",
  },
  {
    name: "Corporate actions",
    href: "/corporate-actions",
  },
  {
    name: "Financial Highlights",
    href: "/financial-highlights",
  },
];

const fonts = [
  "Nexa",
  "Hanifonts",
  "Roboto",
  "Open Sans",
  "Lato",
  "Oswald",
  "Raleway",
  "PlayfairDisplay",
  "Merriweather",
  "PTSerif",
  "NotoSerif",
  "Lora",
];

const bars = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25];
const envName = process.env.ENV_NAME || "test";
const apiUrl = process.env.BACKEND_URL || "https://irp-admin-config-test-back-mp4ugowyca-uc.a.run.app/";

const lines = [0,1,2,3,4];

export const settings = {
  widgets,
  fonts,
  apiUrl,
  bars,
  lines,
  envName,
  urlWidget: envName === "prod" ? "" : `${envName}.`
};
