import React from "react";
import { Select } from "antd";
import { ColorPickerComponent } from "../colorPicker";
import { settings } from "../../../../settings";
import "./style.scss";

export const FontsMenu = ({ setState, state }) => {
  const { Option } = Select;

  const changeHandler = (newFont) => {
    setState((prevState) => {
      prevState.global.fontFamily.en = newFont;
      prevState.global.fontFamily.ar = newFont;

      const newState = { ...prevState };

      return newState;
    });
  };

  return (
    <>
      <Select
        defaultValue={state.global.fontFamily.en || settings.fonts[0]}
        className="font_select"
        onChange={changeHandler}
      >
        {settings.fonts.map((item) => {
          return (
            <Option value={item} key={item}>
              {item}
            </Option>
          );
        })}
      </Select>
      <ColorPickerComponent
        category="fonts"
        type="titleSymbol"
        text="Title/Symbol"
        setColor={setState}
        state={state}
      />
       <ColorPickerComponent
        category="fonts"
        type="subHeader"
        text="Sub Header"
        setColor={setState}
        state={state}
      />
       <ColorPickerComponent
        category="fonts"
        type="body"
        text="Body"
        setColor={setState}
        state={state}
      />
      <ColorPickerComponent
        category="fonts"
        type="tableHeader"
        text="Table Header"
        setColor={setState}
        state={state}
      />
      <ColorPickerComponent
        category="fonts"
        type="tabSelected"
        text="Tab Selected"
        setColor={setState}
        state={state}
      />
      <ColorPickerComponent
        category="fonts"
        type="tabNotSelected"
        text="Tab Not Selected"
        setColor={setState}
        state={state}
      />
      <ColorPickerComponent
        category="fonts"
        type="button"
        text="Button"
        setColor={setState}
        state={state}
      />
      <ColorPickerComponent
        category="fonts"
        type="dialog"
        text="Dialog"
        setColor={setState}
        state={state}
      />
       <ColorPickerComponent
        category="fonts"
        type="link"
        text="Link"
        setColor={setState}
        state={state}
      />
    </>
  );
};
