import React from "react";
import { ColorPickerComponent } from "../colorPicker";

export const BackgroundMenu = ({ setState, state }) => {

  return (
    <>
      <ColorPickerComponent
        category="backgroundColors"
        type="widget"
        text="Widget"
        setColor={setState}
        state={state}
      />
      <ColorPickerComponent
        category="backgroundColors"
        type="tableHeader"
        text="Table Header"
        setColor={setState}
        state={state}
      />
      <ColorPickerComponent
        category="backgroundColors"
        type="tableRow1"
        text="Table Row 1"
        setColor={setState}
        state={state}
      />
      <ColorPickerComponent
        category="backgroundColors"
        type="tableRow2"
        text="Table Row 2"
        setColor={setState}
        state={state}
      />
      <ColorPickerComponent
        category="backgroundColors"
        type="tabSelected"
        text="Tab Bottom Bar Selected"
        setColor={setState}
        state={state}
      />
      <ColorPickerComponent
        category="backgroundColors"
        type="button"
        text="Button"
        setColor={setState}
        state={state}
      />
      <ColorPickerComponent
        category="backgroundColors"
        type="buttonHover"
        text="Button Hover"
        setColor={setState}
        state={state}
      />
      <ColorPickerComponent
        category="backgroundColors"
        type="dialog"
        text="Dialog"
        setColor={setState}
        state={state}
      />
      <ColorPickerComponent
        category="backgroundColors"
        type="topBottomLegend"
        text="Top/Bottom Legend"
        setColor={setState}
        state={state}
      />
      <ColorPickerComponent
        category="backgroundColors"
        type="dateLegend"
        text="Date Legend"
        setColor={setState}
        state={state}
      />
      <ColorPickerComponent
        category="backgroundColors"
        type="legendButton"
        text="Legend Button"
        setColor={setState}
        state={state}
      />
      <ColorPickerComponent
        category="backgroundColors"
        type="legendButtonBorder"
        text="Legend Button Border"
        setColor={setState}
        state={state}
      />
    </>
  );
};
