import React from "react";
import { useDispatch } from "react-redux";
import "./style.scss";
import { toast } from "react-toastify";

export const PublishButton = ({ action, state, UUID }) => {
  const dispatch = useDispatch();

  const setAction = () => {
    toast("Updates successful. Preview on Test Page.", {
      position: "top-right",
      autoClose: 5000,
      closeOnClick: true,
      pauseOnHover: false,
    });
    dispatch(action({state, UUID}));
  };

  return (
    <div>
      <button className="publish-button" onClick={setAction}>Publish</button>
    </div>
  );
};
