import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";
import { appReducers } from "./reducers";
import { watchAppSaga } from "./sagas";

const sagaMiddleware = createSagaMiddleware();

export const store = createStore(
  appReducers,
  composeWithDevTools(applyMiddleware(sagaMiddleware))
);

sagaMiddleware.run(watchAppSaga);
